<template>
  <div class="video_container">
    <div class="hello-ezuikit-js" v-show="isShow">
      <div id="video-playback" style="width:100%;height:100%">
      </div>

    </div>
    <div v-show="!isShow" class="empty_box">
      <div>{{emptyText}}</div>
    </div>
    <!--<div class="data_select">-->
    <!--<div class="block">-->
    <!--<el-date-picker-->
    <!--v-model="date"-->
    <!--type="date"-->
    <!--@change="changeDate"-->
    <!--placeholder="选择日期">-->
    <!--</el-date-picker>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
import { replayVideo} from "../../api/monitoring";
import { Message,} from "element-ui";
export default {
  props:{
  },
  data:()=>({
    accessToken:'',
    player:null,
    emptyText:'',//暂无数据文案
    isShow:true,
    Authorization:null,
    eventId:null,//事件id
  }),
  watch: {
  },
  mounted() {
    this.getRequest()
  },
  methods: {
    //获取地址栏参数
    getRequest() {
      var url = location.href.split('?')[1]; //获取url中"?"符后的字串
      var params = url.split('&');
      console.log(url)
      console.log(params)
      var theRequest = new Object();
      for(var i=0;i<params.length;i++){//遍历数组，拿到json对象
        theRequest[params[i].split('=')[0]] = params[i].split('=')[1]
      }
      if(!theRequest.Authorization){
        console.log('缺少Authorization参数')
      }else if(!theRequest.eventId){
        console.log('缺少eventId参数')
      }else{
        this.Authorization = theRequest.Authorization
        this.eventId = theRequest.eventId;
        this.handleView()
      }

    },
    //阿里云视频播放
    getVideo(source) {
      console.log(source);
      this.player = new Aliplayer({
        "id": "video-playback",
        // "source": 'https://jsy-saas-cloud-test.oss-cn-hangzhou.aliyuncs.com/JsySaasCloud/deviceErrorVideo/lockEvent/137687334_4525_0.mp4',
        "source": source,
        "width": "100%",
        "height": "88%",
        "autoplay": true,
        "isLive": false,
        "rePlay": false,
        "playsinline": true,
        "preload": true,
        "controlBarVisibility": "hover",
        "useH5Prism": true,
        "extraInfo": {
          "crossOrigin": "anonymous"
        },
        "skinLayout": [
          {
            "name": "bigPlayButton",
            "align": "blabs",
            "x": 30,
            "y": 80
          },
          {
            "name": "H5Loading",
            "align": "cc"
          },
          {
            "name": "errorDisplay",
            "align": "tlabs",
            "x": 0,
            "y": 0
          },
          {
            "name": "infoDisplay"
          },
          {
            "name": "tooltip",
            "align": "blabs",
            "x": 0,
            "y": 56
          },
          {
            "name": "thumbnail"
          },
          {
            "name": "controlBar",
            "align": "blabs",
            "x": 0,
            "y": 0,
            "children": [
              {
                "name": "progress",
                "align": "blabs",
                "x": 0,
                "y": 44
              },
              {
                "name": "playButton",
                "align": "tl",
                "x": 15,
                "y": 12
              },
              {
                "name": "timeDisplay",
                "align": "tl",
                "x": 10,
                "y": 7
              },
              {
                "name": "fullScreenButton",
                "align": "tr",
                "x": 10,
                "y": 12
              },
              {
                "name": "volume",
                "align": "tr",
                "x": 5,
                "y": 10
              },
              {
                "name": "snapshot",
                "align": "tr",
                "x": 10,
                "y": 12
              }
            ]
          }]
        }, function (player) {
        console.log("The player is created");
      }
      );
      /* h5截图按钮, 截图成功回调 */
      this.player.on('snapshoted', function (data) {
        var pictureData = data.paramData.base64
        var downloadElement = document.createElement('a')
        downloadElement.setAttribute('href', pictureData)
        var fileName = '录像回放' + Date.now() + '.png'
        downloadElement.setAttribute('download', fileName)
        downloadElement.click()
        pictureData = null
      })
    },
    //查看详情
    handleView(){
      var params = {
        eventId: this.eventId,
      };
      var headers = {
        Authorization:this.Authorization,
        token:this.Authorization
      };
      this.accessToken=''
      replayVideo(params,headers).then(res=> {
        if (res.code == 0) {
          if(res.data.url){
            this.isShow = true
            console.log(res.data.url)
            this.getVideo(res.data.url)
          }else {
            this.isShow = false
            this.emptyText='视频走丢了'
            Message.warning('视频走丢了');
          }
        } else {
          this.isShow = false
          this.emptyText=res.msg
          Message.warning(res.msg);
        }
      });
    },
  }
};
</script>
<style scoped lang="stylus">
.video_container{
  width 100%
  height 100%
  padding: 16px 18px;
  box-sizing: border-box;
  background: #3D3D3D;
}
.hello-ezuikit-js{
  position: relative;
  width 100%
  height 100%
}
.empty_box{
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/img/empty_bg.png") no-repeat;
  background-size: 100% 100%;
}


</style>
